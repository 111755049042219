import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../navbar/navbar.component';
import { ActivatedRoute } from '@angular/router';
import { AppConfigService } from '../../services/app.config.service';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [CommonModule, NavbarComponent],
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent {
  hideMenu: boolean = false;
  isLegacy: boolean = window.location.origin.includes('loopearplugs') || window.location.origin.includes('flip-shop');

  logo!: string;
  storeName!: string;

  constructor(private activatedRoute: ActivatedRoute, private appConfig: AppConfigService) {
    this.storeName = this.appConfig.settings?.storeName || "";
    this.logo = this.appConfig.settings?.storeLogo || "";
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      // Check if the 'menuLess' query parameter exists and has a value of 'true'
      this.hideMenu = params['menuLess'] === 'true';
    });
  }
}
