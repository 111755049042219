import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class ReferrerInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const origin = 'https://branded-tracking-vb.staging.ship24.com/'

    // Check if the URL includes "public/user-tracking-pages/get-configuration"
    if (!environment.production && request.url.includes('public/user-tracking-pages/get-configuration')) {
      const REFERRER_PATTERN = /^https:\/\/([\w-]+\.)?(track\.)?(staging\.)?ship24m?\.com\/?/;
      const match = origin.match(REFERRER_PATTERN);
      const subdomain = match?.[1] ?? "";
      const hash = this.hashedUserSubdomain(subdomain.slice(0, -1));
      const modifiedUrl = `${environment.publicApiEndpoint}/user-tracking-pages/get-configuration/${hash}`;
      request = request.clone({ url: modifiedUrl });
    }

    const modifiedReq = !environment.production ? request.clone({
      setHeaders: { 'Local_Referer': origin }
    }) : request;

    return next.handle(modifiedReq);
  }

  private hashedUserSubdomain = (subdomain: string) => {
    return  CryptoJS.SHA256(subdomain).toString(CryptoJS.enc.Hex).slice(0, 12);
  };
}
