@if (loading) {
  <router-outlet></router-outlet>
}

@if (!loading && !pageError) {
  <app-main-layout>
    <router-outlet></router-outlet>
  </app-main-layout>
}

@if (!loading && pageError) {
  <app-error-page [error]="pageError"></app-error-page>
}

