<nav #navbar id="navbar-sticky" class="bg-primary fixed w-full z-20 top-0 start-0 shadow-sm">
  <div class="relative mx-auto max-w-7xl flex gap-8 py-4 px-4 items-center justify-between">
    <a [href]="appConfig?.storeUrl" target="_blank" class="flex flex-none items-center">
      <img loading="eager" [src]="appConfig?.storeLogo" class="h-8 lg:h-14 max-w-xs object-contain">
    </a>
    <div class="flex-none flex items-center justify-center lg:order-2">
      <app-social-media
        class="hidden lg:flex"
        [linkFacebook]="appConfig?.linkFacebook"
        [linkInstagram]="appConfig?.linkInstagram"
        [linkTiktok]="appConfig?.linkTiktok"
        [linkX]="appConfig?.linkX"
       ></app-social-media>
      <button (click)="toggleMobileMenu()" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-secondary rounded-lg lg:hidden focus:outline-none focus:ring-2 focus:ring-secondary">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
      </button>
    </div>
    <div class="rounded-b-lg flex-grow justify-center w-full bg-primary lg:bg-transparent border-t lg:border-t-0 absolute start-0 bottom-0 transform translate-y-full lg:relative lg:transform-none lg:translate-y-0 lg:flex lg:order-1"
    [@shrink]="!isMobile ? 'show':isMobileMenuOpen ?'show':'hide'"
    [class.hidden]="!isMobileMenuOpen"
    >
      <ul #menu class="flex flex-col w-full p-4 lg:justify-evenly lg:items-center lg:flex-row lg:p-0 lg:mt-0 lg:border-0">
        @for (item of navMenus; track item.name) {
          <li>
            <a [href]="item.link" target="_blank" class="hover:underline flex w-full lg:block lg:w-auto text-lg font-normal text-secondary">{{ item.name }}</a>
          </li>
        }
      </ul>

      <app-social-media
        class="flex lg:hidden p-4 lg:p-0"
        [linkFacebook]="appConfig?.linkFacebook"
        [linkInstagram]="appConfig?.linkInstagram"
        [linkTiktok]="appConfig?.linkTiktok"
        [linkX]="appConfig?.linkX"
       ></app-social-media>
    </div>
  </div>
</nav>

<div class="w-full" [style.height.px]="navbarHeight"></div>
