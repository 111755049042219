import { Component, OnInit } from '@angular/core';
import { AppConfigService, ResponseCode } from './services/app.config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading: boolean = false;
  pageError!: ResponseCode | null;

  constructor(private configService: AppConfigService) {
    this.loading = this.configService?.loading;
    this.pageError = this.configService?.error;
  }

  ngOnInit() {
    const isLoopearplugsSubdomain: boolean = window.location.origin.includes('loopearplugs');
    if (isLoopearplugsSubdomain) this.loadIframeResizerScript();
    this.checkEnvConfig()
  }

  loadIframeResizerScript() {
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.contentWindow.min.js';
    script.integrity = 'sha512-14SY6teTzhrLWeL55Q4uCyxr6GQOxF3pEoMxo2mBxXwPRikdMtzKMYWy2B5Lqjr6PHHoGOxZgPaxUYKQrSmu0A==';
    script.crossOrigin = 'anonymous';
    script.referrerPolicy = 'no-referrer';

    document.body.appendChild(script);
  }

  checkEnvConfig() {
    // temporary solution for env config
    if (
      window?.location?.origin?.includes('.staging.ship24.com')
      && !environment.publicApiEndpoint.includes('.staging.ship24.com')
    ) {
      confirm(` 
      ⚠️ Need to config environment.prod.ts
      // ============= STAGING ENV ============= //
      production: false,
      apiEndpoint: 'https://api.staging.ship24.com/api',
      publicApiEndpoint: 'https://api.staging.ship24.com/public',
     `)
    }
  }
}
