import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-social-media',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './social-media.component.html',
  styleUrl: './social-media.component.scss',
})
export class SocialMediaComponent {
  @Input() linkFacebook!: string;
  @Input() linkInstagram!: string;
  @Input() linkTiktok!: string;
  @Input() linkX!: string;
}
