import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SocialMediaComponent } from '../social-media/social-media.component';
import { AppConfigService, IGeneralSetting } from '../../services/app.config.service';
import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';

interface MenuItem {
  name: string;
  link: string;
}

interface Menu {
  [key: string]: MenuItem;
}

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, SocialMediaComponent],
  templateUrl: './navbar.component.html',
  animations: [
    trigger('shrink', [
      state('hide', style({ display: 'none', height: 0, opacity: 0 })),
      transition('show => hide', [
        style({ display: 'block', height: AUTO_STYLE, }),
        animate(300 * 0.05 + 'ms ease'),
      ]),
      transition('hide => show', [
        style({ display: 'none', height: 0, }),
        animate(300 * 0.5 + 'ms ease'),
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  isBrowser: boolean;
  // storeLogo!: string;
  // storeName!: string;
  // storeUrl!: string;
  // linkFacebook!: string;
  // linkInstagram!: string;
  // linkTiktok!: string;
  // linkX!: string;
  navMenus!: MenuItem[];
  isMobileMenuOpen = false; // Initial state of the mobile menu
  appConfig!: IGeneralSetting | null;

  @ViewChild('navbar') navbarElement!: ElementRef;
  navbarHeight: number = 0;
  MOBILE_BREAK_POINT = 1024 // Screen lg;
  isMobile = false

  constructor(
    private config: AppConfigService,
    @Inject(PLATFORM_ID) private platformId: any,  private cdr: ChangeDetectorRef) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.appConfig = this.config?.settings;
    this.isMobile = window.document.body.offsetWidth < this.MOBILE_BREAK_POINT

    if (this.appConfig?.menu) this.navMenus = Object.values(this.appConfig?.menu).map((menuItem: any) => ({
      name: menuItem.item,
      link: menuItem.link
    }));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.calculateNavbarHeight();
    this.cdr.detectChanges(); // Manually trigger change detection
    this.isMobile = window.document.body.offsetWidth < this.MOBILE_BREAK_POINT
  }

  ngAfterViewInit(): void {
    this.calculateNavbarHeight();
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  ngOnInit() {
    if (this.isBrowser) {
      // Add a click event listener to the entire document
      document.addEventListener('click', this.onDocumentClick.bind(this));
    }
  }

  ngOnDestroy() {
    if (this.isBrowser) {
      // Remove the click event listener when the component is destroyed
      document.removeEventListener('click', this.onDocumentClick.bind(this));
    }
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  closeMobileMenu() {
    this.isMobileMenuOpen = false;
  }

  onDocumentClick(event: MouseEvent) {
    // Close the mobile menu if the click is outside the menu area
    const menuElement = document.getElementById('navbar-sticky');
    if (menuElement && !menuElement.contains(event.target as Node)) {
      this.closeMobileMenu();
    }
  }

  calculateNavbarHeight() {
    const navbarNativeElement: HTMLElement = this.navbarElement.nativeElement;
    this.navbarHeight = navbarNativeElement ? navbarNativeElement.offsetHeight : 0;
  }
}
