<section class="bg-white">
  <div class="container flex items-center min-h-screen px-6 py-12 mx-auto">
    <div class="flex flex-col items-center max-w-lg mx-auto text-center">
      <p class="p-3 text-sm font-medium text-red-500 rounded-full bg-red-50">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
          />
        </svg>
      </p>
      <h1
        class="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl"
      >
        {{ error === 'PAGE_NOT_EXIST' ? 'Page not found' : error === 'PAGE_NOT_ACTIVE' ? 'Page is not Activated' : 'Something Went Wrong' }}
      </h1>
      <p class="mt-4 text-gray-500">
        {{ error === 'PAGE_NOT_EXIST' ? "Sorry, it looks like this page is not configured yet." : error === 'PAGE_NOT_ACTIVE' ? "Sorry, it looks like this page is not active." : "Sorry, it looks like something went wrong." }} {{ "  Please contact the website's owner for assistance." }}
      </p>

      <div class="flex w-full mt-6 sm:w-auto">
        <button
          (click)="hardRefresh()"
          class="w-full rounded-md bg-indigo-600 mt-4 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Refresh
        </button>
      </div>
    </div>
  </div>
</section>
