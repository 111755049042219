import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './services/app.config.service';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ReferrerInterceptor } from './common/interceptors/referrer.interceptor';
import { APP_BASE_HREF } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    LoadingComponent,
    ErrorPageComponent,
    MainLayoutComponent,
    AppRoutingModule,
    RouterOutlet
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: ReferrerInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigService) => appConfig.initializeApp(),
      multi: true,
      deps: [AppConfigService],
    },
    provideHttpClient(withFetch()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
