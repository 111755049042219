<div class="min-h-screen max-w-screen bg-[#F9F9F9]">
  @if (!hideMenu && !isLegacy) {
    <app-navbar></app-navbar>
  }

  <main>
    <div [ngClass]="{'py-4 sm:py-10': isLegacy }">
      <div [ngClass]="{'mx-auto sm:max-w-2xl px-4 sm:px-6 lg:px-8': isLegacy }">
        <div [ngClass]="{'relative isolate flex flex-col flex-1': isLegacy }">
          <!-- <app-logo class="mx-auto" [logo]="logo"></app-logo> -->
          <h2 *ngIf="isLegacy && storeName" class="text-2xl text-center font-medium tracking-tight sm:text-4xl">
            {{ 'Track ' + storeName + ' status' }}
          </h2>

          <div [ngClass]="{ 'mt-8 sm:mt-10' : isLegacy && storeName }">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
