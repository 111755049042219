import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
interface AppConfigResponse {
  code?: ResponseCode;
  data?: {
    setting?: {
      general?: IGeneralSetting
    }
  };
}

export interface IMarketingBloc {
  bloc1?: {
    title?: string;
    link?: string;
    image?: string;
  },
  bloc2?: {
    title?: string;
    link?: string;
    image?: string;
  },
  bloc3?: {
    title?: string;
    link?: string;
    image?: string;
  },
  bloc4?: {
    title?: string;
    link?: string;
    image?: string;
  }
};

export interface IMarketingBanner {
  banner1?: {
    link?: string;
    image?: string;
  },
  banner2?: {
    link?: string;
    image?: string;
  };
}

interface MenuItem {
  item: string;
  link: string;
}

export interface IBrandingMenu {
  [key: string]: MenuItem;
}

export interface IGeneralSetting {
  storeName?: string,
  storeUrl?: string,
  storeLogo?: string,
  storeFavIcon?: string,
  menu?: IBrandingMenu,
  linkFacebook?: string,
  linkInstagram?: string,
  linkTiktok?: string,
  linkX?: string,
  fontPrimary?: string,
  colorPrimary?: string,
  colorSecondary?: string,
  marketingBlocs?: IMarketingBloc,
  marketingBanners?: IMarketingBanner,
  lookupByOrder: boolean,
  lookupByTn: boolean,
  updatedAt: string,
  disableShip24Branding?: boolean,
  disableShip24FullInfoRedirect: boolean
}

export enum ResponseCode {
  OK = 'OK',
  TN_NOT_FOUND = 'TN_NOT_FOUND',
  PAGE_NOT_EXIST = 'PAGE_NOT_EXIST',
  PAGE_NOT_ACTIVE = 'PAGE_NOT_ACTIVE',
  UNEXPECTED = 'UNEXPECTED',
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  public loading: boolean = true;
  public settings: IGeneralSetting | null = null;
  public error: ResponseCode | null = null;
  isLegacy: boolean = window.location.origin.includes('loopearplugs') || window.location.origin.includes('flip-shop');

  constructor(
    private http: HttpClient,
    private titleService: Title
  ) {}

  public async load(): Promise<any> {
    try {
      const url = window.location.origin;
      const REFERRER_PATTERN = /^https:\/\/([\w-]+\.)?(track\.)?(staging\.)?ship24m?\.com\/?/;
      const match = url.match(REFERRER_PATTERN);
      const subdomain = match?.[1] ?? "";
      const hash = this.hashedUserSubdomain(subdomain.slice(0, -1));

      const response: AppConfigResponse = await firstValueFrom(
        this.http.get(`${url}/public/user-tracking-pages/get-configuration/${hash}`)
      );

      if (response?.code === ResponseCode.OK && response?.data?.setting) {
        return { data: response?.data?.setting?.general };
      } else if (response?.code === ResponseCode.PAGE_NOT_EXIST) {
        return { error: ResponseCode.PAGE_NOT_EXIST };
      } else if (response?.code === ResponseCode.PAGE_NOT_ACTIVE) {
        return { error: ResponseCode.PAGE_NOT_ACTIVE };
      } else {
        return { error: ResponseCode.UNEXPECTED };
      }
    } catch (error) {
      return { error: ResponseCode.UNEXPECTED };
    }
  }

  public async initialConfig() {
    this.loading = true;

    const config = await this.load();

    this.settings = config?.data;
    this.error = config?.error;

    this.titleService.setTitle(this.settings?.storeName || "Tracking Page");
    this.setFavicon(this.settings?.storeFavIcon);
    this.setThemeColors(this.settings?.colorPrimary, this.settings?.colorSecondary);
    this.setPrimaryFont(this.settings?.fontPrimary);

    this.loading = false;
  }

  initializeApp(): () => Promise<any> {
    return () => this.initialConfig();
  }

  private setThemeColors(primary?: string, secondary?: string) {
    const colorPrimaryContrast = this.generateTextBasedOnColor(primary);
    const colorSecondaryContrast = this.generateTextBasedOnColor(secondary);

    document.documentElement.style.setProperty('--color-primary', primary ? primary : '');

    if (this.isLegacy) document.documentElement.style.setProperty('--color-primary', '#6243B9');

    document.documentElement.style.setProperty('--color-secondary', secondary ? secondary : '');
    document.documentElement.style.setProperty('--color-primary-contrast', colorPrimaryContrast);
    document.documentElement.style.setProperty('--color-secondary-contrast', colorSecondaryContrast);
  }

  private setFavicon(href?: string): void {
    const head = document.head;

    // Check if a favicon link already exists
    let faviconLink = head.querySelector('link[rel="icon"]') as HTMLLinkElement;

    if (!faviconLink) {
      // If it doesn't exist, create a new link element
      faviconLink = document.createElement('link');
      faviconLink.rel = 'icon';
      faviconLink.type = 'image/x-icon'
      head.appendChild(faviconLink);
    }

    // Set the href attribute to the provided path
    faviconLink.href = href || 'favicon.ico';
  }

  private setPrimaryFont(fontName?: string): void {
    if (!fontName) return;

    // Override the font dynamically for the component
    const style = document.createElement('style');
    style.textContent = `body { font-family: '${fontName}', sans-serif; }`;
    document.head.appendChild(style);
  }

  private generateTextBasedOnColor(hexColor?: string) {

    if (!hexColor) return '';

   // Remove the # symbol if present
    hexColor = hexColor.replace("#", "");

    // Convert the hex color to RGB
    var bigint = parseInt(hexColor, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

    // Calculate the relative luminance of the color
    let relativeLuminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Choose black or white based on the relative luminance
    let textColor = relativeLuminance > 0.5 ? "#000000" : "#FFFFFF";

    return textColor;
  }

  private hashedUserSubdomain = (subdomain: string) => {
    return  CryptoJS.SHA256(subdomain).toString(CryptoJS.enc.Hex).slice(0, 12);
  };
}
