export const environment = {
  // ============= PRODUCTION ENV ============= //
  production: true,
  apiEndpoint: 'https://api.ship24.com/api',
  publicApiEndpoint: 'https://api.ship24.com/public',
  backofficeEndpoint: 'https://api.ship24.com/backoffice',


  // ============= STAGING ENV ============= //
  // production: true,
  // apiEndpoint: 'https://api.staging.ship24.com/api',
  // publicApiEndpoint: 'https://api.staging.ship24.com/public',
  // backofficeEndpoint: 'https://api.staging.ship24.com/backoffice',
};
